/**
 * Environment configuration object.
 *
 * @typedef {Object} Env
 * @property {string} ENVIRONMENT - The current environment.
 * @property {string} DEFAULT_LANGUAGE - The default language.
 * @property {boolean} LANGUAGE_SELECTOR - Whether the language selector is enabled.
 * @property {string} PARKING_ATTENDANT_URL - The URL for the parking attendant.
 * @property {string} PARKING_DASHBOARD_COMMON_URL - The URL for the parking dashboard common.
 * @property {string} PARKING_INVENTORY_URL - The URL for the parking inventory.
 * @property {string} ENFORCEMENT_URL - The URL for enforcement.
 * @property {string} OCCUPANCY_URL - The URL for occupancy.
 * @property {string} PM360_URL - The URL for pm360.
 * @property {string} BFF_URL - The URL for the backend for frontend.
 * @property {string} SOCKET_URL - The URL for the socket connection.
 * @property {string} THEME - The current theme.
 * @property {string} CONTACT_URL - The URL for contacting EasyPark partners.
 * @property {string} MAPBOX_TOKEN - The Mapbox token.
 * @property {string} CSV_SEPARATOR - The separator used in CSV files.
 */
const env = {
    ENVIRONMENT: 'usproduction',
    DEFAULT_LANGUAGE: 'en-US',
    LANGUAGE_SELECTOR: false,
    PARKING_ATTENDANT_URL: 'https://parking-attendant.parkmobile.io',
    PARKING_DASHBOARD_COMMON_URL:
        'https://parking-dashboard-common.parkmobile.io',
    PARKING_INVENTORY_URL: 'https://inventory-mfa.usa.ezprk.net',
    ENFORCEMENT_URL: 'https://enforcement-mfa.easypark.net',
    OCCUPANCY_URL: 'https://occupancy-mfa.usa.ezprk.net',
    PM360_URL: 'https://360.parkmobile.io',
    BFF_URL: '/api',
    SOCKET_URL: '',
    THEME: 'pm360',
    CONTACT_URL: 'https://parkmobile.io/contact-page/',
    MAPBOX_TOKEN:
        'pk.eyJ1IjoiZWFzeXBhcmsiLCJhIjoiY2x4eDV5M3VxMmQ1bjJscXd4N2ZoeDZjNCJ9.Js0ISrj4AZ66doLW9MInkA',
    MIXPANEL_TOKEN: '31a50b957e624de978290e204ab43fc6',
    CSV_SEPARATOR: ',',
};

window.env = env;
