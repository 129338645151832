/**
 * Environment configuration object.
 *
 * @typedef {Object} Env
 * @property {string} ENVIRONMENT - The current environment.
 * @property {string} DEFAULT_LANGUAGE - The default language.
 * @property {boolean} LANGUAGE_SELECTOR - Whether the language selector is enabled.
 * @property {string} PARKING_ATTENDANT_URL - The URL for the parking attendant.
 * @property {string} PARKING_DASHBOARD_COMMON_URL - The URL for the parking dashboard common.
 * @property {string} PARKING_INVENTORY_URL - The URL for the parking inventory.
 * @property {string} ENFORCEMENT_URL - The URL for enforcement.
 * @property {string} OCCUPANCY_URL - The URL for occupancy.
 * @property {string} PM360_URL - The URL for pm360.
 * @property {string} BFF_URL - The URL for the backend for frontend.
 * @property {string} SOCKET_URL - The URL for the socket connection.
 * @property {string} THEME - The current theme.
 * @property {string} CONTACT_URL - The URL for contacting EasyPark partners.
 * @property {string} MAPBOX_TOKEN - The Mapbox token.
 * @property {string} CSV_SEPARATOR - The separator used in CSV files.
 */
const env = {
    ENVIRONMENT: 'euproduction',
    DEFAULT_LANGUAGE: 'en',
    LANGUAGE_SELECTOR: true,
    PARKING_ATTENDANT_URL: 'https://parking-attendant.easypark.net',
    PARKING_DASHBOARD_COMMON_URL:
        'https://parking-dashboard-common.easypark.net',
    PARKING_INVENTORY_URL: 'https://inventory-mfa.easypark.net',
    ENFORCEMENT_URL: 'https://enforcement-mfa.easypark.net',
    OCCUPANCY_URL: 'https://occupancy-mfa.easypark.net',
    PM360_URL: 'https://360.parkmobile.io',
    BFF_URL: '/api',
    SOCKET_URL: '',
    THEME: 'easypark',
    CONTACT_URL: 'https://www.easyparkpartners.com/contact/',
    MAPBOX_TOKEN:
        'pk.eyJ1IjoiZWFzeXBhcmsiLCJhIjoiY2x4eDV5M3VxMmQ1bjJscXd4N2ZoeDZjNCJ9.Js0ISrj4AZ66doLW9MInkA',
    MIXPANEL_TOKEN: '6fbb157707b45dc04233b44fbcb0977b',
    CSV_SEPARATOR: ';',
};

window.env = env;
